import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
/** Page components */
import Layout from '../components/layout'
import Meta from '../components/meta'
import Hero from '../components/news/hero'
import Stories from '../components/news/stories'
import MainContent from '../components/news/main-content'
import PopularPosts from '../components/news/popular-posts'
import localeCheck from '../components/utils/locale-check'

const News = () => {
	const locale = 'en-ZA'
	const defaultLocale = 'en-AU'
	const { page, stories, popular, heroBanner } = useStaticQuery(graphql`
		query newsPageQueryZa {
			page: allContentfulPage(
				filter: { slug: { eq: "news" }, node_locale: { eq: "en-ZA" } }
			) {
				edges {
					node {
						node_locale
						slug
						seoTitle
						seoKeywords
						seoDescription
						media {
							file {
								url
							}
						}
						body {
							json
						}
						updatedAt(locale: "en-ZA", formatString: "llll")
					}
				}
			}
			stories: allContentfulPost(
				filter: {
					slug: {nin: [null]}, node_locale: {eq: "en-ZA"},
					contentful_id: {ne: "4C5lPaagfRxOolLxODiDen"},
					countryAvailability: { eq: "ZA" }
				}
				sort: { fields: dateAndTime, order: DESC }
				) {
				edges {
					node {
						category
						slug
						seoTitle
						seoDescription
						node_locale
						dateAndTime(formatString: "DD MMMM, YYYY")
						image {
							file {
								url
							}
							description
						}
					}
				}
			}
			popular: allContentfulPost(
				sort: {fields: dateAndTime, order: DESC},
				filter: {
					slug: {nin: [null]}, node_locale: {eq: "en-ZA"},
					contentful_id: {ne: "4C5lPaagfRxOolLxODiDen"},
					countryAvailability: { eq: "ZA" }
				}) {
				edges {
					node {
						node_locale
						seoTitle
						seoDescription
						slug
						category
						dateAndTime(formatString: "DD MMMM, YYYY")
						image {
							file {
								url
							}
						}
					}
				}
			}
			heroBanner: allContentfulPost(
				filter: {
					contentful_id: {eq: "4C5lPaagfRxOolLxODiDen"}
					node_locale: { eq: "en-ZA" }
				}
			) {
				edges {
					node {
						seoTitle
						seoDescription
						seoKeywords
						slug
						image {
							file {
								url
							}
						}
						node_locale
						category
					}
				}
			}
		}
	`)
	/** Filter Array by Locale */
	const sliceStories = stories.edges.slice(0, 5)
	const slicePopular = popular.edges.slice(0, 6)
	/** Destruct Filtered Array to Node */
	const {
		seoTitle,
		seoKeywords,
		seoDescription,
	} = page.edges[0].node
	const getNode = filteredArray => {
		return filteredArray.map((item, i) => {
			return item.node
		})
	}

	const [{ node: heroNode }] = heroBanner.edges
	const checkedLocale = localeCheck(locale, defaultLocale)

	const hero = {
		title: heroNode.seoTitle,
		description: heroNode.seoDescription,
		image: heroNode?.image?.file?.url,
		category: heroNode.category,
		cta: [
			{
				color: 'white',
				fill: false,
				name: 'Read more',
				link: `${locale}/${heroNode.slug}`,
			},
		],
	}


	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
				lang={locale}
			/>
			<Hero {...hero} />
			<Stories stories={getNode(sliceStories)} checkedLocale={checkedLocale} />
			<MainContent
				stories={getNode(sliceStories)}
				locale={locale}
				checkedLocale={checkedLocale}
			/>
			<PopularPosts
				popularPosts={getNode(slicePopular)}
				checkedLocale={checkedLocale}
			/>
		</Layout>
	)
}
export default News
